<template>
    <div class="apply">
        <ul class="apply-wrap">
            <li>
                <p class="p6">提现账户</p>
                <router-link to="/shopAccount?from=2" tag="div">
                    <p>{{ bankText }}</p>
                    <svg t="1598322964711" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="8493" width="16" height="16"
                        style="position: absolute; right: 0;">
                        <path
                            d="M384.32 797.512a30.088 30.088 0 0 1-21.288-51.392L597.144 512 363.032 277.816a30.088 30.088 0 0 1 0-42.568 30.088 30.088 0 0 1 42.568 0l255.392 255.464a30.088 30.088 0 0 1 0 42.568L405.6 788.696a30.04 30.04 0 0 1-21.28 8.816z"
                            fill="#7c7c7c" p-id="8494" />
                    </svg>
                </router-link>
            </li>
            <li>
                <p class="p6">提现金额</p>
                <input type="number" placeholder="请输入提现金额" v-model="applyMoney" />
            </li>
        </ul>
        <div class="info-box">
            <p v-if="hasCount">
                当月剩余提现次数
                <span class="red">{{ hasCount }}次</span>
            </p>
            <p class="p7">
                可提现金额
                <span>￥{{ wallet.shop_wallet | numFloat }}</span>
            </p>
        </div>
        <div class="apply-sub" @click="postData">
            <p>提交</p>
        </div>
        <div class="remark">
            <p>目前为人工提现，每次提现时，请向饭十荟的财务客服人员（ 微信号：{{configInfo.kefu_wx2}} ）发送：“小饭您好，我要提现！”的指令，即可很快到账。</p>
            <div class="switch-box">
                <p>1.一个月可提现{{wallet.limit_number}}次；</p>
                <p>2.各银行到账时间不同，请自行留意；</p>
                <p>3.添加客服微信，优先审核到账。</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BankDefault,
        applyTixian,
        ChackMoney
    } from "@/http/api";
    export default {
        data() {
            return {
                shopUserInfo: {},
                configInfo: {},
                wallet: {},
                hasCount: 0,
                pay_types: 1,
                bank_id: "",
                bankText: "",
                applyMoney: "",
                isPost: false
            };
        },

        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },

        mounted() {
            this.configInfo = this.$LStorage.getItem("curSiteInfo");
            this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
            this.checkMoney();
            this.checkBankInfo();
        },

        methods: {
            // 商户核验资金
            async checkMoney() {
                const res = await ChackMoney({
                    data: {
                        shop_id: this.shopUserInfo.shop_id
                    }
                });
                //console.log(res)
                this.wallet = res.data;
                this.hasCount = this.wallet.limit_number - this.wallet.already_number
            },

            checkBankInfo() {
                let bankInfo = this.$store.state.ShopBankInfo;
                if (bankInfo) {
                    this.bank_id = bankInfo.bank_id;
                    this.bankText = bankInfo.bank_name + "(" + bankInfo.bank_card.substr(bankInfo.bank_card.length -
                        4) + ")";
                } else {
                    this.BankDefault()
                }
            },

            //获取用户银行卡信息
            async BankDefault() {
                let res = await BankDefault({
                    data: {
                        shop_id: this.shopUserInfo.shop_id
                    }
                });
                console.log(res)
                if (res.code == 200) {
                    this.bank_id = res.data.bank_id
                    this.bankText = res.data.bank_mode
                } else {
                    this.bankText = "未绑卡，请先绑卡";
                }
            },

            // 提交 核验数据
            postData() {
                if (this.isPost) {
                    this.$toast("请勿重复提交");
                } else {
                    if (this.hasCount > 0) {
                        if (this.applyMoney > Number(this.wallet.shop_wallet)) {
                            this.$toast("超过现有资产");
                            return;
                        } else if (this.bank_id == "") {
                            this.$toast("请选择提现账户");
                            return;
                        } else if (this.applyMoney == "") {
                            this.$toast("请输入要提现金额");
                            return;
                        } else {
                            this.applyTixian();
                        }
                    } else {
                        this.$toast("很抱歉，本月剩余提现次数不足");
                    }
                }
            },

            // 申请提现
            async applyTixian() {
                this.isPost = true;
                let res = await applyTixian({
                    data: {
                        shop_id: this.shopUserInfo.shop_id,
                        shop_user_id: this.shopUserInfo.shop_user_id,
                        withdraw_amount: this.applyMoney,
                        bank_id: this.bank_id,
                        pay_types: this.pay_types,
                    }
                });
                if (res.code == "200") {
                    this.$toast.success("提现申请成功");
                    this.$router.go(-1);
                } else {
                    this.$toast.fail(res.msgs);
                }
                this.isPost = false;
            }
        },
        filters: {
            numFloat(num) {
                if (num) {
                    num = Number(num);
                    return num.toFixed(2);
                }
                return "0.00";
            }
        }
    };
</script>

<style lang="scss" scoped>
    .apply {
        width: 100%;
        height: 15rem;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .apply-wrap {
            width: 100%;
            background: #fff;
            padding-left: 0.22rem;
            box-sizing: border-box;

            li {
                display: flex;
                padding-top: 0.66rem;
                padding-bottom: 0.18rem;
                box-sizing: border-box;
                border-bottom: 1px solid #f1f1f1;
                align-items: center;

                // justify-content: space-between;
                div {
                    display: flex;
                    width: 70%;

                    p {
                        font-size: 0.25rem;
                        color: #7c7c7c;
                        flex: 1;
                        // margin-right: 3rem;
                    }
                }

                .p6 {
                    font-size: 0.27rem;
                    color: #000022;
                    margin-right: 0.67rem;
                }

                input {
                    font-size: 0.26rem;
                }
            }
        }

        .info-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.2rem;
            font-size: 0.26rem;
            color: #494949;
            margin-top: 0.33rem;

            .red {
                color: #ff5400;
            }
        }

        .apply-sub {
            width: calc(100% - 0.48rem);
            height: 0.84rem;
            background: #ff5400;
            color: #fff;
            text-align: center;
            line-height: 0.84rem;
            font-size: 0.28rem;
            margin: 0 auto;
            margin-top: 2.16rem;
            border-radius: 0.38rem;
        }

        .remark {
            font-size: 0.26rem;
            color: #ff5400;
            margin: 0.8rem 0.6rem 0;
        }
    }
</style>
